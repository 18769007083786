























































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { RootRouter } from "@/utils/newRoutePathConstant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
} from "@/store/modules";
import ownershipStore from "@/store/modules/ownership";
import { District } from "@/store/models/meta";
import { BSToAD } from "bikram-sambat-js";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";

@Component({
  components: {
    NepaliDatePicker,
  },
})
export default class YatayatRegister extends Vue {
  isCreate: boolean = true;
  municipality: any[] = [];
  districts: District[] = [];
  active: number = 0;

  photoIdFile: File[] = [];
  companyCertificateFile: File[] = [];
  panCertificateFile: File[] = [];

  get RootRouter() {
    return RootRouter;
  }

  get ownership() {
    return ownershipStore.ownership;
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.ownership.vehicleOwner.registrationDate = bsAD;
      this.ownership.vehicleOwner.registrationDateBs = e;
    }
  }

  bsDateChange1(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.ownership.profile.citizenshipIssuingDate = bsAD;
      this.ownership.profile.citizenshipIssuingDateBs = e;
    }
  }

  handleLogoChange(e: any) {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      this.photoIdFile = [e.target.files[0]];
      this.ownership.profile.photoId = e.target.files[0];
    } else {
      this.$snotify.error("Please upload .jpeg or .jpg or .png file");
      e = "";
      this.photoIdFile = [];
      this.ownership.profile.photoId = null;
    }
  }

  handleRegistrationChange(e: any) {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      this.companyCertificateFile = [e.target.files[0]];
      this.ownership.vehicleOwner.companyCertificate = e.target.files[0];
    } else {
      this.$snotify.error("Please upload .jpeg or .jpg or .png file");
      e = "";
      this.companyCertificateFile = [];
      this.ownership.vehicleOwner.companyCertificate = null;
    }
  }

  handlePanChange(e: any) {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      this.photoIdFile = [e.target.files[0]];
      this.ownership.vehicleOwner.panCertificate = e.target.files[0];
    } else {
      this.$snotify.error("Please upload .jpeg or .jpg or .png file");
      e = "";
      this.photoIdFile = [];
      this.ownership.vehicleOwner.panCertificate = null;
    }
  }

  async onRegisterClick() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      commonstore.showLoading();
      await ownershipStore.createOwnership();
      this.$snotify.success("Saved Successfully");
      commonstore.hideLoading();
      this.$router.push(RootRouter.RegistrationLandingPage);
    }
  }

  async created() {
    let vm = this;
    await metaStore.loadProvince();
    await metaStore.loadDis();
    const id = vm.$route.query["id"];
    if (id) {
      vm.isCreate = false;

      //await ownershipStore.loadOwnershipById(id);
    } else {
      ownershipStore.resetField();
    }
  }

  async onProvinceChange(e: any) {
    this.districts = await metaStore.loadDistrict(e);
  }

  async onDistrictChange(e: any) {
    this.municipality = await metaStore.loadMuncipality(e);
  }

  get province() {
    return metaStore.provinceList;
  }
  get districtList() {
    return metaStore.districtL;
  }
}
