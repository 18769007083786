import { render, staticRenderFns } from "./yatayatRegister.vue?vue&type=template&id=467becad&scoped=true&"
import script from "./yatayatRegister.vue?vue&type=script&lang=ts&"
export * from "./yatayatRegister.vue?vue&type=script&lang=ts&"
import style0 from "./yatayatRegister.vue?vue&type=style&index=0&id=467becad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467becad",
  null
  
)

export default component.exports